// 教师
<template>
  <div class="role">
    <role-page-layout>
      <div class="container students parents">
        <div class="div_mod div_mod1 div_mod1">
          <div class="div_text" v-html="typeBz"></div>
        </div>
        <div
          style="margin-top: 0.4rem"
          v-for="(block, index) in blocks"
          :key="block.id"
        >
          <div class="div_tit">
            <h2>{{ block.title }}</h2>
          </div>
          <!-- 栏目简介 -->
          <div v-html="block.bz"></div>

          <div v-if="block.code === 'Service'">
            <BlockLink :blocks="childrenBlocks[index]" />
          </div>

          <div v-else-if="block.code === 'OfficialWeChatAccount'">
            <BlockQRcode :blocks="childrenBlocks[index]" />
          </div>

          <div v-else-if="block.code === 'links'">
            <BlockLink2 :blocks="childrenBlocks[index]" />
          </div>

          <div v-else class="div_mod div_mod4 div_mod4">
            <BlockCover :block="block" />
          </div>
        </div>
      </div>
    </role-page-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RolePageLayout from './components/RolePageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import BlockLink from './components/BlockLink.vue'
import BlockLink2 from './components/BlockLink2.vue'
import BlockQRcode from './components/BlockQRcode.vue'
import BlockCover from './components/BlockCover.vue'
export default {
  components: {
    RolePageLayout,
    BlockLink,
    BlockQRcode,
    BlockLink2,
    BlockCover,
  },
  computed: {
    ...mapState({
      roles: (state) => state.yzsyStore.roles,
    }),
    typeId() {
      return this.roles?.find((item) => item.path === this.$route.path)?.id
    },
    typeBz() {
      return this.roles?.find((item) => item.path === this.$route.path)?.bz
    }
  },
  data() {
    return {
      blocks: [],
      childrenBlocks: [],
    }
  },
  methods: {
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
      this.getChildBlocks()
    },
    async getChildBlocks() {
      for (const block of this.blocks) {
        const categories = await useGetCategories(block.id)
        this.childrenBlocks.push(categories.list)
      }
    },
  },
  mounted() {
    this.getBlocks()
  },
}
</script>

